import React from 'react';
import Cookies from 'js-cookie';



type ReferralParamsContextModel = {
    [key in MarketingParamsType]: string;
} & {
    updateParam: (param: MarketingParamsType, value: string) => void;
    setCookie: (name: string, value: string, expires?: number) => void
    getCookie: (name: string) => string | undefined;
};

const ReferralParamsContext = React.createContext<ReferralParamsContextModel | null>(null);


export const ReferralParamsWrapper = ({ children }: { children: React.ReactNode }) => {
    const [params, setParams] = React.useState<ReferralParamsContextModel | null>(null);


    // On Mount
    React.useEffect(() => {
        if ( typeof window === 'undefined' ) return;


        const location = window.location;
        const queryParamsString = location.search;

        const _params = {} as ReferralParamsContextModel;
        const URLParams = new URLSearchParams(queryParamsString);


        /**
         * Create a params object - accounting for the aliases to set cookies before setting the final state.
         */
        URLParams.forEach((_value, key) => {
            // Check for cookeie names in the map
            const found = QueryToCookieMap.find(item => {
                return item.q === key || item.aliases?.includes(key);
            });

            // Sanitize value
            const value = sanitizeValue(_value);

            // Skip adding to _params list if the value is falsey
            if ( !value ) return;

            if ( found ) {
                _params[found.c] = value;
            } else {
                _params[key as MarketingParamsType] = value;
            }
        });


        /**
         * Check for Traking Id change
         * 
         * If there's a tune tracking id that doesn't match the current cookie value, we need to clear all the previous marketing values 
         * (last click wins)
         */
        const trakingParams = QueryToCookieMap.find(item => item.q === 'tunetrackingid');
        const queryTrakingId = URLParams.get(trakingParams?.q || '');
        const cookieTrackingId = Cookies.get(trakingParams?.c || '');

        if ( queryTrakingId && queryTrakingId !== cookieTrackingId ) {
            console.log('Tune ID has changed. Clearing all cookies');
            clearAllCookies();
        }


        /**
         * Set cookies.
         * 
         * Cookies must be set before updating params state so that components that want to perform updates on query params 
         * can use "useReferralParams" custom hook to make sure cookies were set.
         */
        // console.log('Setting referral cookies', _params);
        Object.keys(_params).forEach(name => {
            const value = _params[name as MarketingParamsType];
            
            setCookie(name, value);
        });
        

        // console.log('Referral cookies are set');
        // console.log(`Getting all cookies for testing:`, Cookies.get());
        setParams(() => {
            if (queryTrakingId && queryTrakingId !== cookieTrackingId) {
                // Reset context params state to current query params
                return _params;
            } else {
                /**
                 * Set params form cookies for the context
                 */
                const coockieValues = {} as Record<keyof typeof MarketingTermMap, string>;
                CookiesValueMap.forEach(item => {
                    // console.log(item.v, ':', 'Skip?', !sanitizeValue(item.v));
                    
                    // Skip undefined values or values that are "unsanitary" :) 
                    if (item.v === undefined || !sanitizeValue(item.v)) return;

                    coockieValues[item.c] = item.v;
                });
                // Update context params state by merging context params with current query params
                return Object.assign({}, coockieValues, _params);
            }
        });
        
    }, []);


    /**
     * Updates a referral param based on param name both in cookies and in React Context
     * @param param one of the available referral params
     */
    const updateParam = (param: MarketingParamsType, value: string) => {
        // Update cookies
        setCookie(param, value)

        // Update Context
        setParams((prev) => {
            const newState: ReferralParamsContextModel = prev || {} as ReferralParamsContextModel;
            return {
                ...newState,
                [param]: value
            }
        });
    };


    // Functions
    const cookieDomain = React.useMemo(() => {
        let cookieDomain = `.${location.host.replace("www.", "")}`;
        if (cookieDomain.indexOf("localhost") >= 0) {
            cookieDomain = "localhost";
        }
        return cookieDomain;
    }, []);

    const sanitizeValue = (input: string | undefined): string => {
        return input ? input.replace(/{.+}/g, '') : '';
    };


    const setCookie = (name: string, value: string, expires: number = 30) => {
        Cookies.set(name, value, { domain: cookieDomain, expires: expires });
    };

    const getCookie = (name: string) => {
        return Cookies.get(name);
    };

    const clearAllCookies = () => {
        QueryToCookieMap.map(item => {
            Cookies.remove(item.c, { domain: cookieDomain });
        });
    };


    // Memoed Context
    const memoed = React.useMemo(() => {
        return params ? { ...params, updateParam, setCookie, getCookie } : null;
    }, [params]);

    return <ReferralParamsContext.Provider value={memoed}>
        { children }
    </ReferralParamsContext.Provider>
};

/**
 * Custom hook for easy access to the referral params context.
 * @returns Memoized object of referral cookies 
 */
export const useReferralParams = () => {
    return React.useContext(ReferralParamsContext);
};




type MarketingParamsType = 
    'tuneTrackingId' |
    'tuneOfferId' |
    'referralCode' |
    'marketingMedium' |
    'marketingSource' |
    'marketingCampaign' |
    'marketingContent' |
    'marketingTerm' |
    'marketingPartnerId' |
    'marketingPartnerVar1' |
    'marketingPartnerVar2' |
    'marketingPartnerVar3' |
    'marketingPartnerVar4' |
    'marketingPartnerVar5' |
    'marketingPartnerOfferId' | 
    'referralFactoryCode'

const MarketingTermMap: Record<string, { c: MarketingParamsType; aliases?: string[] }> = {
    'tunetrackingid': { c: 'tuneTrackingId' },
    'tuneofferid': { c: 'tuneOfferId' },
    'rc': { c: 'referralCode' },
    'utm_medium': { c: 'marketingMedium' },
    'utm_source': { c: 'marketingSource' },
    'utm_campaign': { c: 'marketingCampaign' },
    'utm_content': { c: 'marketingContent' },
    'utm_term': { c: 'marketingTerm' },
    'pid': { c: 'marketingPartnerId' },
    'pvar1': { 
        c: 'marketingPartnerVar1', 
        aliases: ['click_id', 'gclid'] 
    },
    'pvar2': { c: 'marketingPartnerVar2' },
    'pvar3': { c: 'marketingPartnerVar3' },
    'pvar4': { c: 'marketingPartnerVar4' },
    'pvar5': { c: 'marketingPartnerVar5' },
    'pofferid': { c: 'marketingPartnerOfferId' },
    'code': { c: 'referralFactoryCode' }
};


const QueryToCookieMap = Object.keys(MarketingTermMap).map((key) => {
    const item = MarketingTermMap[key as keyof typeof MarketingTermMap];
    return { 
        q: key, 
        c: item.c, 
        aliases: item.aliases
    } as {
        q: keyof typeof MarketingTermMap;
        c: MarketingParamsType;
        aliases?: string[];
    }
});

const CookiesValueMap = Object.keys(MarketingTermMap).map((key) => {
    const item = MarketingTermMap[key as keyof typeof MarketingTermMap];
    
    return {
        c: item.c,
        v: Cookies.get(item.c)
    }
});