import React from "react";
import config from "./config";
import { BreakpointKeys, breakpoints } from "./breakpoints";
import { ClassObjectType, CSSVariable } from "./types";
import { color, getColor } from "./utils";
import textAlign from "./modules/typography/text-align";


type Selector = {
    [key: string]: React.CSSProperties | null
}

/**
 * Common styles (selectors are added without a prefix) 
 */
export const common: ClassObjectType[] = Object.keys(breakpoints).map((bp) => {
    const breakpoint = bp as BreakpointKeys;
    const breakpointObject = breakpoints[breakpoint];    
    
    return {
        breakpoint,
        rule: {
            /**
             * Base font size for base 10 rem system
             */
            'html': {
                fontSize: `${breakpointObject.base}px`,
                scrollBehavior: 'smooth',
            },
            /**
             * Body and Inputs
             */
            'html.wf-inactive body': (() => {
                if (breakpoint === '') {
                    return {
                        margin: 0,
                        padding: 0,
                        lineHeight: '1.5',
                        letterSpacing: '-0.001rem',
                        fontSize: '1.5rem',
                    }
                }
                return {};
            })(),
            'main': (() => {
                if (breakpoint === '') {
                    return {
                        width: '100vw'
                    }
                }
                return {};
            })(),
            'body, .body': (() => {
                if (breakpoint === '') {
                    return {
                        position: 'relative',
                        margin: 0,
                        padding: 0,
                        lineHeight: '1.5',
                        letterSpacing: 0,
                        fontSize: '1.5rem',
                        fontFamily: `${config.fontFamily}`,
                        overflowX: 'hidden'
                    }
                }
                return {};
            })(),
            'body, button, input, textarea': ((): React.CSSProperties => {
                if( breakpoint === '' ) {
                    return { 
                        fontFamily: `${config.fontFamily}`,
                        fontSize: '1.5rem',
                        color: config.colors.primary[700][0]
                    }
                }
                return {}
            })(),
            /**
             * Headings
             */
            ...headings(breakpoint),
            ...h1(breakpoint),
            ...h2(breakpoint),
            ...h3(breakpoint),
            ...h4(breakpoint),
            ...h5(breakpoint),
            ...h6(breakpoint),

            /**
             * Paragraph and inline elements
             */
            ...p(breakpoint),
            ...anchors(breakpoint),

            /**
             * Misc
             */
            ...lists(breakpoint),
            ...multilineHighlight(breakpoint),
            ...scrollbars(breakpoint),
            ...table(breakpoint),
            'hr': ((): React.CSSProperties => {
                if (breakpoint === '') {
                    return { 
                        border: 'none',
                        borderBottom: `1px solid ${config.colors.primary['200'][0]}`
                    }
                }
                return {};
            })(),
            'br': ((): React.CSSProperties => {
                if( breakpoint === '' ) {
                    return { display: 'none' }
                } else if(breakpoint === 'md') {
                    return { display: 'block' }
                }
                return {};
            })(),
            '::selection': ((): React.CSSProperties => {
                if (breakpoint === '') {
                    return {
                        backgroundColor: 'rgba(199, 218, 235, .8)',
                        color: 'inherit'
                    };
                }
                return {};
            })(),
            'input:autofill, textarea:autofill, select:autofill': ((): React.CSSProperties => {
                if (breakpoint === '') {
                    return {
                        fontFamily: config.fontFamily,
                        boxShadow: `0 0 0 100px white inset !important`
                    };
                }
                return {};
            })(),
            'main > section:last-child': (() => {
                if (breakpoint === '') {
                    return {
                        marginBottom: 0
                    }
                }

                return {};
            })(),
            '.drop-shadow-1': (() => {
                if (breakpoint === '') {
                    return {
                        boxShadow: `0 .1rem .1rem 0 ${color('shadow:700')},
                                    0 0.15rem 0.3rem 0 ${color('shadow:600')},
                                    0 0.3rem 1.5rem 0 ${color('shadow:500')}`
                    }
                }
                return {};
            })(),
            '.drop-shadow-2': (() => {
                if (breakpoint === '') {
                    return {
                        boxShadow: `0 .1rem .2rem 0 ${color('shadow:700')},
                                    0 0.3rem 0.4rem 0 ${color('shadow:600')},
                                    0 0.6rem 2.5rem 0 ${color('shadow:500')}`
                    }
                }
                return {};
            })(),
            '.drop-shadow-3': (() => {
                if (breakpoint === '') {
                    return {
                        boxShadow: `0 .1rem .2rem 0 ${color('shadow:700')},
                                    0 0.5rem 1rem 0 ${color('shadow:600')},
                                    0 1rem 4rem 0 ${color('shadow:500')}`
                    }
                }
                return {};
            })(),
            '.drop-shadow-4': (() => {
                if (breakpoint === '') {
                    return {
                        boxShadow: `0 .1rem .3rem 0 ${color('shadow:700')},
                                    0 1rem 1.5rem 0 ${color('shadow:600')},
                                    0 2.5rem 8rem 0 ${color('shadow:500')}`
                    }
                }
                return {};
            })(),

        }
    }
});

function anchors(breakpoint: BreakpointKeys): Selector {
    if( breakpoint !== '' ) return {};

    const exclude = `:not([class*="Mui"])`;

    // Add only for no-breakpoint breakpoint 
    return {
        [`a${exclude}`]: {
            color: config.colors.primary[700][0],
            textDecoration: 'underline'
        },
        [`h1 a${exclude}, h2 a${exclude}, h3 a${exclude}, h4 a${exclude}, h5 a${exclude}, h6 a${exclude}`]: {
            color: config.colors.primary[700][0],
            textDecoration: 'none'
        },
        [`h1 a${exclude}:hover, h2 a${exclude}:hover, h3 a${exclude}:hover, h4 a${exclude}:hover, h5 a${exclude}:hover, h6 a${exclude}:hover`]: {
            color: config.colors.primary[900][0],
            textDecoration: 'none',
        },
        [`a${exclude}:hover`]: {
            color: config.colors.accent[1050][0],
            textDecoration: 'underline'
        },
        
        [`a${exclude}:visited`]: {
            // color: config.colors.secondary[1100][0],
        }
    }
}


function multilineHighlight(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        '.multiline-highlight': {
            // lineLeight: 1.4,
            // padding: '1rem 0', 
            // width: '400px',
            // margin: '0px -1rem'
        },
        '.multiline-highlight span': {
            backgroundColor: getColor('secondary', 500),
            color: getColor('primary', 800),
            display: 'inline',
            padding: '0rem 1.5rem',
            borderRadius: '1rem',
            /* Needs prefixing */
            boxDecorationBreak: 'clone',
            WebkitBoxDecorationBreak: 'clone'
        }
    } : {};
}

function lists(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'ul, ol': {
            paddingLeft: '1.5rem',
            marginTop: '2rem',
            marginBottom: '2rem',
        },
        'ul li, ol li': {
            paddingLeft: '0.5rem',
            marginBlockStart: '1rem',
            marginBlockEnd: '1rem',
        },
        'ul.clean, ol.clean': {
            marginLeft: 0,
            paddingLeft: 0,
            listStyle: 'none'
        },
        'ul.clean li, ol.clean li': {
            marginLeft: 0,
            paddingLeft: 0,
        },
    } : {};
}

function headings(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'html.wf-inactive h1, html.wf-inactive .h1, html.wf-inactive h2, html.wf-inactive .h2, html.wf-inactive h3, html.wf-inactive .h3, html.wf-inactive h4, html.wf-inactive .h4, html.wf-inactive h5, html.wf-inactive .h5, html.wf-inactive h6, html.wf-inactive .h6': {
            fontWeight: 700,
        },
        'h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6': {
            fontFamily: config.fontFamily,
            color: config.colors.primary[700][0],
            fontWeight: 800,
            lineHeight: `1.13`,
            marginBlock: 0,
        },
        'h1 strong, .h1 strong, h2 strong, .h2 strong, h3 strong, .h3 strong, h4 strong, .h4 strong, h5 strong, .h5 strong, h6 strong, .h6 strong': {
            fontWeight: 800,
        }
    } : {}
}

function h1(breakpoint: BreakpointKeys): Selector {
    if (breakpoint === '') {
        return {
            'html.wf-inactive h1, html.wf-inactive .h1': {
                fontSize: '5rem',
                letterSpacing: '-0.034em',
                lineHeight: 1.13,
            },
            'h1, .h1': {
                fontSize: '5rem',
                letterSpacing: '-0.045em',
            },
        }
    }

    if (['md', 'lg', 'xl', 'xxl'].includes(breakpoint)) {
        return {
            'html.wf-inactive h1, html.wf-inactive .h1': {
                fontSize: '6.15rem',
                letterSpacing: '-0.023em',
                lineHeight: 1.14,
            },
            'h1, .h1': {
                fontSize: '6.2rem',
                letterSpacing: '-0.032em',
            },
        }
    }

    return {};
}

function h2(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'html.wf-inactive h2, html.wf-inactive .h2': {
            fontSize: '4.5rem',
            letterSpacing: '-0.035em',
            lineHeight: 1.138,
        },
        'h2, .h2': {
            fontSize: '4.5rem',
            letterSpacing: '-0.025em',
        }
    } : {};
}

function h3(breakpoint: BreakpointKeys): Selector {
    if (breakpoint === '') {
        return {
            'h3, .h3': {
                fontSize: '3rem',
                letterSpacing: '-0.025em',
            }
        };
    }

    if (['md', 'lg', 'xl', 'xxl'].includes(breakpoint)) {
        return {
            'h3, .h3': {
                fontSize: '3.6rem',
                letterSpacing: '-0.025em',
            }
        };
    }


    return {};
}

function h4(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'h4, .h4': {
            fontSize: '2.5rem',
            letterSpacing: '-0.025em',
            lineHeight: '1.5 !important',
        }
    } : {};
}

function h5(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'h5, .h5': {
            fontSize: '2rem',
            letterSpacing: '-0.025em',
            lineHeight: '1.5 !important',
        }
    } : {};
}

function h6(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'h6, .h6': {
            fontSize: '1.8rem',
            letterSpacing: '-0.025em',
            lineHeight: '1.5 !important',
        }
    } : {};
}

function p(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'p': {
            marginBlockStart: '1.5rem',
            marginBlockEnd: '1.5rem',
            lineHeight: '2.5rem',
        }
    } : {};
}

function scrollbars(breakpoint: BreakpointKeys): Selector {    
    return breakpoint === '' ? {
        '.no-scrollbar::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
            background: 'transparent !important'
        },
        '.thin-scrollbar::-webkit-scrollbar': {
            width: '2px',
            height: '2px',
            backgroundColor: config.colors.grey['400'][0]
        },
        '.thin-scrollbar::-webkit-scrollbar-thumb': {
            borderRadius: '2px',
            backgroundColor: config.colors.primary['800'][0]
        },
        '.thin-scrollbar::-webkit-scrollbar-track': {
            width: '2px',
            backgroundColor: config.colors.grey['400'][0]
        },
        '.scroll-x-snap': {
            scrollSnapType: 'x mandatory',
            scrollSnapStop: 'always'
        },
        '.scroll-x-snap > *:first-child': {
            scrollSnapAlign: 'start',
            marginLeft: '30%'
        },
        '.scroll-x-snap > *': {
            scrollSnapAlign: 'center'
        },
        '.scroll-x-snap > *:last-child': {
            scrollSnapAlign: 'end',
            marginRight: '30%'
        }
    } : {};
}


function table(breakpoint: BreakpointKeys): Selector {
    return breakpoint === '' ? {
        'table:not(section-table)': {
            borderCollapse: 'collapse',
            width: '100%'
        },
        'table:not(section-table) td, table:not(section-table) th': {
            padding: '0.5rem 1rem',
            border: `1px solid ${color('primary:400')}`,
            textAlign: 'left',

        },
        'table:not(section-table) td:first-child, table:not(section-table) th:first-child': {
            textAlign: 'left'
        },
        'table:not(section-table) tr:nth-child(odd) td': {
            backgroundColor: color('grey:100')
        }
    } : {}
}